import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import "../Card/Card.css";

export default function ActionAreaCard(props) {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={props.img}
          alt="green iguana"
          id="card-media"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.heading}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", fontWeight: "600" }}
          >
            {props.subheading}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", marginTop: "10px" }}
          >
            {props.para}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
