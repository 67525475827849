import React from "react";
import "../Home/Home.css";
import NewsPaper from "../../images/newspaper.png";
import Footer from "../footer/Footer";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Newsicon1 from "../../images/newsicon1.png";
import Newsicon2 from "../../images/newsicon2.png";
import Newsicon3 from "../../images/newsicon3.png";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Cheifeditor from "../../images/chiefEditor.png";

const Home = () => {
  return (
    <>
      <div>
        <header className="masthead text-white text-center">
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-xl-9 mx-auto">
                <Fade top>
                  <h1
                    className="mb-5"
                    style={{
                      color: "#fff",
                      fontSize: "3rem",
                      textShadow: "2px 4px 3px #000",
                    }}
                  >
                    ताज़ा समाचार, खास रिपोर्ट और विश्लेषण एक ही स्थान पर।
                    राजनीति, खेल, मनोरंजन, और अंतर्राष्ट्रीय घटनाओं पर लाइव
                    अपडेट्स
                  </h1>
                </Fade>
              </div>
            </div>
          </div>
        </header>

        <section className="features-icons bg-light text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <Fade left>
                  <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                    <div className="features-icons-icon d-flex">
                      <img src={Newsicon1} alt="news paper" id="news-paper" />
                    </div>
                    <h3>जानकारी प्राप्त करें</h3>
                    <p className="lead mb-0">
                      हर दिन दुनिया भर से नवीनतम अपडेट पाएं।
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="col-lg-4">
                <Fade bottom>
                  <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                    <div className="features-icons-icon d-flex">
                      <img src={Newsicon2} alt="news paper" id="news-paper" />
                    </div>
                    <h3>दैनिक मुख्य बातें</h3>
                    <p className="lead mb-0">
                      जानें वो महत्वपूर्ण कहानियाँ और जानकारियाँ जो आपके लिए
                      मायने रखती हैं।
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="col-lg-4">
                <Fade right>
                  <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                    <div className="features-icons-icon d-flex m-auto">
                      <img src={Newsicon3} alt="news icon" id="news-paper" />
                    </div>
                    <h3>तत्काल समाचार</h3>
                    <p className="lead mb-0">
                      ताजा और विश्वसनीय समाचार संक्षेप में प्राप्त करें।
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>

        <section className="showcase">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div
                className="col-lg-6 order-lg-2 text-white showcase-img"
                style={{
                  backgroundImage: `url(${NewsPaper})`,
                }}
              ></div>
              <Slide left>
                <div className="col-lg-6 order-lg-1 my-auto showcase-text">
                  <h2>ताज़ा खबरें, हर डिवाइस पर</h2>
                  <p className="lead mb-0">
                    हर महत्वपूर्ण खबर, हर समय और हर डिवाइस पर। लाइव अपडेट्स के
                    साथ प्रमुख सुर्खियाँ!
                  </p>
                </div>
              </Slide>
            </div>
            <div className="row no-gutters">
              <div
                className="col-lg-6 text-white showcase-img"
                style={{
                  backgroundImage: `url(${NewsPaper})`,
                }}
              ></div>
              <Slide right>
                <div className="col-lg-6 my-auto showcase-text">
                  <h2>हर डिवाइस पर बेहतरीन अनुभव</h2>
                  <p className="lead mb-0">
                    हमारी साइट पूरी तरह प्रतिक्रियाशील है, जिससे आप मोबाइल,
                    टैबलेट या डेस्कटॉप पर भी बिना किसी रुकावट के समाचार पढ़ सकते
                    हैं।
                  </p>
                </div>
              </Slide>
            </div>
          </div>
        </section>
        <section className="testimonials text-center bg-light">
          <div className="container">
            <h2 className="mb-5">हमारी टीम से मिलें</h2>
            <div className="row">
              <div className="col-lg-4">
                <Fade bottom>
                  <div className="testimonial-item mx-auto mb-5 mb-lg-0">
                    <img
                      className="img-fluid rounded-circle mb-3"
                      src={Cheifeditor}
                      alt="Somendra Gopaliya"
                    />
                    <h5>सोमेंद्र गोपालिया</h5>
                    <p className="font-weight-light mb-0">मुख्य संपादक</p>
                  </div>
                </Fade>
              </div>
              <div className="col-lg-4">
                <Fade bottom>
                  <div className="testimonial-item mx-auto mb-5 mb-lg-0">
                    <img
                      className="img-fluid rounded-circle mb-3"
                      src="https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                      alt="Fred S."
                    />
                    <h5>Fred S.</h5>
                    <p className="font-weight-light mb-0">वेब विकास</p>
                  </div>
                </Fade>
              </div>
              <div className="col-lg-4">
                <Fade bottom>
                  <div className="testimonial-item mx-auto mb-5 mb-lg-0">
                    <img
                      className="img-fluid rounded-circle mb-3"
                      src="https://images.pexels.com/photos/1172784/pexels-photo-1172784.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                      alt="Sarah W."
                    />
                    <h5>Sarah W.</h5>
                    <p className="font-weight-light mb-0">ग्राफिक डिज़ाइनर</p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>

        <section
          className="call-to-action text-white text-center"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-9 mx-auto" style={{ color: "black" }}>
                <h2 className="mb-4">
                  शुरू करने के लिए तैयार हैं? अभी साइन अप करें!
                </h2>
                <p className="mb-4" style={{ fontSize: "1.5rem" }}>
                  यह कुछ प्रेरक पाठ है जिससे आप...
                </p>
              </div>

              <div className="col-12 col-md-12">
                <Link to="/signup">
                  <Button variant="contained" className="animated-button">
                    साइन अप करें!
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Home;
