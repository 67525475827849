import React from "react";
import "../footer/Footer.css"; // Importing CSS file
import { Link } from "react-router-dom";
import fotrlogo from "../../images/logo_white.png";

export const Footer = () => {
  return (
    // <div className='container-fluid footer text-white py-5'>
    //     <div className='row'>
    //         {/* About Section */}
    //         <div className='col-md-4 mb-4'>
    //             <h4>बारे में</h4>
    //             <p>
    //                 गर्विक हिंदुस्तान समूह भारत का सबसे प्रतिष्ठित और विविधतापूर्ण मीडिया समूह है।
    //                 आपातकाल के जवाब में 2000 में स्थापित, इसका पूरा चरित्र इस केंद्रीय विश्वास पर
    //                 आधारित है कि स्वतंत्र मीडिया लोकतंत्र की नींव है।
    //             </p>
    //             <img src={fotrlogo} alt='' style={{'width': '83%'}}></img>
    //         </div>

    //         {/* Popular Posts Section */}
    //         <div className='col-md-4 mb-4'>
    //             <h4>लोकप्रिय पोस्ट</h4>
    //             <ul className='list-unstyled'>
    //                 <li>
    //                     <h6>राजनीतिक</h6>
    //                     <p>पहाड़ियों को समतल करना किसी दिन पहाड़ उन्हें पकड़ सकता है लेकिन कानून कभी नहीं</p>
    //                 </li>
    //                 <li>
    //                     <h6>मनोरंजन</h6>
    //                     <p>विलियम बक रोजर्स को उनके प्रक्षेप पथ से उड़ाकर कक्षा में भेज दिया गया</p>
    //                 </li>
    //                 <li>
    //                     <h6>राजनीतिक</h6>
    //                     <p>और तुम्हें पता है कि तुम तब कहाँ थे लड़कियाँ लड़कियाँ और पुरुष थे</p>
    //                 </li>
    //             </ul>
    //         </div>

    //         <div className='col-md-4 mb-4'>
    //             <h4>न्यूज़लेटर शामिल होना</h4>
    //             <p>हमारे न्यूज़लेटर के लिए साइन अप करें और ऐसे विशेष सौदे प्राप्त करें जो आपको कहीं और नहीं मिलेंगे, सीधे आपके इनबॉक्स में!</p>
    //             <div className=''>
    //             <h5>संपर्क में रहें</h5>
    //             <div className="social-icons">
    //                 <a href="#"><i className="fab fa-facebook-f mx-2"></i></a>
    //                 <a href="#"><i className="fab fa-twitter mx-2"></i></a>
    //                 <a href="#"><i className="fab fa-instagram mx-2"></i></a>
    //                 <a href="#"><i className="fab fa-linkedin-in mx-2"></i></a>
    //             </div>
    //         </div>
    //         </div>
    //     </div>

    //     {/* Social Media Links */}
    //     <div className='row mt-4'>

    //     </div>
    // </div>
    <div className="container-fluid" id="footer-main">
      <div className="row">
        <div className="col-md-6" id="sec-1">
          <i className="fa-solid fa-copyright"></i>
          <p>
            {new Date().getFullYear()} गर्वित हिंदुस्तान सर्वाधिकार सुरक्षित.
          </p>
        </div>
        <div className="col-md-6" id="sec-2">
          <ul>
            <li>
              <i
                className="fa-brands fa-whatsapp"
                style={{ fontSize: "20px" }}
              ></i>
            </li>
            <li>
              <i
                className="fa-brands fa-facebook"
                style={{ fontSize: "20px" }}
              ></i>
            </li>
            <li>
              <i
                className="fa-brands fa-linkedin"
                style={{ fontSize: "20px" }}
              ></i>
            </li>
            <li>
              <i
                className="fa-solid fa-envelope"
                style={{ fontSize: "20px" }}
              ></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
