// src/App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import LandingPage from "../src/pages/LandingPage/LandingPage"; // Main component for routes
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <Router>
      <LandingPage />
    </Router>
  );
};

export default App;
