import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { CircularProgress, TextField } from "@mui/material";
import Header from "../header/Header.js";
import Footer from "../footer/Footer.js";
import "../dashboard/Dashboard.css";

export const Dashboard = () => {
  const [pdfDetails, setPdfDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(""); // Date input in YYYY-MM-DD
  const [displayDate, setDisplayDate] = useState(""); // Date to display in DD/MM/YYYY
  const [currentPdfs, setCurrentPdfs] = useState([]); // Changed to an array

  // Get today's date in DD/MM/YYYY format
  const getTodayDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Firebase se PDF data fetch karna
  useEffect(() => {
    const fetchPdfDetails = async () => {
      try {
        const pdfCollectionRef = collection(
          firestore,
          "Admin/published_news_Data/pdf_Lists"
        );
        const snapshot = await getDocs(pdfCollectionRef);

        const details = snapshot.docs.map((doc) => ({
          pdfName: doc.data().pdf_Name,
          pdfDate: doc.data().pdf_Date, // Ensure this is a valid date string
          pdfUrl: doc.data().pdf_URL + "?alt=media",
        }));

        // Log the fetched PDF details to the console
        console.log("Fetched PDF Details:", details);

        // Date ke hisaab se sort karna
        details.sort(
          (a, b) =>
            new Date(b.pdfDate.split("/").reverse().join("-")) -
            new Date(a.pdfDate.split("/").reverse().join("-"))
        );

        // Set PDF details to state
        setPdfDetails(details);

        // Set current PDFs to today's date PDFs
        const todayPdfs = details.filter(
          (pdf) => pdf.pdfDate === getTodayDate()
        );
        setCurrentPdfs(todayPdfs);
      } catch (error) {
        console.error("PDF fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPdfDetails();
  }, []);

  const handleDateChange = (event) => {
    const date = event.target.value; // YYYY-MM-DD format
    setSelectedDate(date);

    // Convert the date to DD/MM/YYYY format for display
    const [year, month, day] = date.split("-");
    const formattedDate = `${day}/${month}/${year}`;
    setDisplayDate(formattedDate);

    // Filter PDFs based on the selected date in DD/MM/YYYY format
    const filteredPdfs = pdfDetails.filter(
      (pdf) => pdf.pdfDate === formattedDate
    );

    // Set current PDFs to the filtered results
    setCurrentPdfs(filteredPdfs);
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div className="mainsection">
          {/* <Header /> */}
          <h2 className="todaynew">Today's Times</h2>

          <TextField
            label="Select Date"
            type="date"
            value={selectedDate} // Keep this in YYYY-MM-DD format
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginBottom: "20px" }}
            inputProps={{
              max: new Date().toISOString().split("T")[0], // Prevent selecting future dates
            }}
          />
          {currentPdfs.length > 0 ? (
            currentPdfs.map((pdf, index) => (
              <div
                key={index}
                style={{ marginBottom: "7px" }}
                className="listdate"
              >
                <h3>{pdf.pdfName}</h3>
                <a href={pdf.pdfUrl} target="_blank" rel="noopener noreferrer">
                  View News
                </a>
                <p>Date: {pdf.pdfDate}</p>
              </div>
            ))
          ) : (
            <p>No PDFs available for the selected date.</p>
          )}
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
