import { React, useState } from "react";
import "../Contact/Contact.css";
import Footer from "../footer/Footer";
import Button from "@mui/material/Button";
import UnderlineImage from "../../images/underline.png";
import Swal from "sweetalert2"; // Import SweetAlert

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });
  const [error, setError] = useState("");

  const { name, mobile, email, message } = formData;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    if (!name || !mobile || !email || !message) {
      return "All fields are required.";
    }

    if (!/^[0-9]{10}$/.test(mobile)) {
      return "Invalid mobile number. Please enter a valid 10-digit number.";
    }

    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      return "Invalid email address. Please enter a valid email address.";
    }

    return "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");

    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: validationError,
        // footer: '<a href="#">Why do I have this issue?</a>',
      });
      return;
    }

    // alert("Form submitted successfully");
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data send successfully",
      showConfirmButton: false,
      timer: 1500,
    });
    // Integrate form submission API

    setFormData({
      name: "",
      mobile: "",
      email: "",
      message: "",
    });
    setError("");
  };

  return (
    <div>
      <div className="container-fluid px-0">
        <div className="contact-banner">
          <h1 className="py-5">हमसे संपर्क करें</h1>
        </div>
        <div className="container-fluid">
          <div className="row">
            <iframe
              title="Google Maps Embed"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3548.4038147257393!2d77.49484657152719!3d27.2064687954313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3973a3341bf5dc91%3A0x124d2f2d78131600!2s1%2F158%2C%20Jawahar%20Nagar%20Rd%2C%20Housing%20Board%20Colony%2C%20Jawahar%20Nagar%2C%20Bharatpur%2C%20Rajasthan%20321001!5e0!3m2!1sen!2sin!4v1728978014651!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <h1 id="mail-form-id">हमें मेल भेजो</h1>
        <div id="underline-img">
          <img
            src={UnderlineImage}
            alt="underline"
            style={{ marginTop: "43px" }}
          />
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-8">
            <div style={{ display: "flex", gap: "66px" }}>
              <div className="form-controls">
                <div>
                  <i className="fa fa-home" id="contact-form-icon"></i>
                  <h1>पता</h1>
                  <p style={{ opacity: "0.5" }} id="address">
                    अनामिका कम्युनिकेशंस नेटवर्क 1/156 हाउसिंग बोर्ड, जवाहर नगर,
                    भरतपुर, राजस्थान 321001
                  </p>
                </div>
                <div>
                  <i className="fa fa-phone" id="contact-form-icon"></i>
                  <h1>टेलीफ़ोन</h1>
                  <p style={{ opacity: "0.5" }}>9414307422</p>
                </div>
                <div>
                  <i className="fa fa-envelope" id="contact-form-icon"></i>
                  <h1>मेल पता</h1>
                  <p style={{ opacity: "0.5" }}>Info@Garvit.com</p>
                </div>
                <div>
                  <i className="fa fa-globe" id="contact-form-icon"></i>
                  <h1>वेबसाइट</h1>
                  <p style={{ opacity: "0.5" }}>www.Garvit.com</p>
                </div>
              </div>
              <form id="contact-form" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="आपका नाम"
                  id="contact-from-input"
                  value={name}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="mobile"
                  placeholder="आपका मोबाइल नंबर"
                  id="contact-from-input"
                  value={mobile}
                  onChange={handleChange}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="आपका ईमेल"
                  id="contact-from-input"
                  value={email}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="message"
                  placeholder="संदेश"
                  id="contact-from-input"
                  value={message}
                  onChange={handleChange}
                />

                <Button
                  variant="contained"
                  id="contact-from-button"
                  type="submit"
                >
                  मेसेज भेजें
                </Button>
              </form>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
