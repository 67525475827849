// src/GummySlider.js
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "../header/Header"; // Import your Header component
import Home from "../Home/Home"; // Import your Home component
import About from "../About/About"; // Import your About component
import Contact from "../Contact/Contact"; // Import your Contact component
import Newlogin from "../newlogin/Newlogin"; // Import your Signin component
import Mysignup from "../mysignup/Mysignup"; // Import your Signup component
import Dashboard from "../dashboard/Dashboard";

const GummySlider = () => {
  const location = useLocation(); // Hook to get the current location

  // Determine whether to show the header
  const showHeader = !(
    location.pathname === "/signin" || location.pathname === "/signup"
  );

  return (
    <div>
      {showHeader && <Header />} {/* Conditionally render Header */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/signin" element={<Newlogin />} />
        <Route path="/signup" element={<Mysignup />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </div>
  );
};

export default GummySlider;
