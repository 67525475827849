import React from "react";
import "../About/About.css";
import TestimonialImages from "../../images/editor.png";
import FacebookIcon from "../../images/facebook.svg";
import TwitterIcon from "../../images/twitter.svg";
import GoogleIcon from "../../images/google.svg";
import WhatsappIcon from "../../images/whatsapp.svg";
import People from "../../images/peoplesays.png";
import Footer from "../footer/Footer";
import ActionAreaCard from "../Card/ActionAreaCard";
import CheifEditor from "../../images/chiefEditor.png";
// import Header from "../header/Header";
import UnderlineImage from "../../images/underline.png";
import { motion } from "framer-motion"; // Importing framer-motion for animations

const About = () => {
  const socialIcons = [
    {
      name: "facebook",
      url: FacebookIcon,
      image: "fa fa-facebook-f",
    },
    {
      name: "twitter",
      url: TwitterIcon,
      image: "fa fa-twitter",
    },
    {
      name: "google",
      url: GoogleIcon,
      image: "fa fa-google",
    },
    {
      name: "whatsapp",
      url: WhatsappIcon,
      image: "fa fa-linkedin",
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }} // Initial animation
      animate={{ opacity: 1 }} // On animation
      transition={{ duration: 1 }} // Animation duration
    >
      {/* <Header /> */}
      <div className="container-fluid" id="about-sec">
        <div className="row">
          <motion.div
            className="about-us"
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 50 }}
          >
            <h1>हमारे बारे में</h1>
          </motion.div>
        </div>
        <div className="row">
          <div className="col-md-12" id="about-us-sec-2">
            <motion.h1
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              हमारे बारे में
              <div id="underline-img">
                <img src={UnderlineImage} alt="underline" />
              </div>
            </motion.h1>
            <motion.p
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
            >
              गर्विक हिंदुस्ता समूह भारत का सबसे सम्मानित और विविधतापूर्ण मीडिया
              समूह है। आपातकाल के जवाब में 2000 में स्थापित, इसका पूरा चरित्र इस
              केंद्रीय विश्वास पर आधारित है कि एक स्वतंत्र मीडिया लोकतंत्र की
              नींव है। 24 वर्षों से अधिक समय से, इसने इस सिद्धांत पर कड़ी मेहनत
              की है। कहानी दर कहानी। संस्करण दर संस्करण। मंच दर मंच। इसने
              विश्वसनीयता, उत्कृष्टता, विश्वास और द्विपक्षीयता की एक गहरी विरासत
              बनाई है। इसे देश में पत्रकारिता के स्वर्ण मानक के रूप में व्यापक
              रूप से सराहा जाता है। तीखी ध्रुवों से चिह्नित परिदृश्य में, इसका
              केवल एक राजनीतिक संरेखण है: भारतीय संविधान। ITG के मीडिया उद्यम की
              शुरुआत प्रतिष्ठित इंडिया टुडे पत्रिका से हुई। 4 दशकों से अधिक समय
              तक यह भारतीय पत्रकारिता की निर्विवाद उच्च तालिका थी। इसने महान
              कहानियों, महान पत्रकारों, महान संपादकों को पेश किया। यह अपनी
              सावधानी के लिए प्रसिद्ध था। इसने मानक स्थापित किए। आज ITG
              ब्रह्मांड चार 24/7 राष्ट्रीय टेलीविजन चैनलों, 18 डिजिटल
              प्लेटफार्मों और नौ पत्रिकाओं के विशाल कैनवास में विस्तारित हो गया
              है। यह हर महीने 200 मिलियन दर्शकों तक पहुंचता है और मानवीय मामलों
              को प्रभावित करने वाले हर विषय को कवर करता है: राजनीति और
              अर्थव्यवस्था से लेकर खेल, व्यंग्य, कला, व्यापार, मनोरंजन,
              विलासिता, स्वास्थ्य, विवाह, प्रौद्योगिकी, विज्ञान, पर्यावरण,
              सेक्स, रिश्ते, पालन-पोषण, जीवन तक। यह एक मल्टी-ब्रांड,
              मल्टी-प्लेटफ़ॉर्म, मल्टी-वर्टिकल मीडिया समूह है। लेकिन इसके सभी
              विविध कैनवास एक सामान्य अटूट धागे से बंधे हैं: विश्वसनीयता और
              उत्कृष्टता।
              {/* Rest of the paragraph */}
            </motion.p>
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              हमारी महान टीम
            </motion.h2>
            <div id="underline-img">
              <img
                src={UnderlineImage}
                alt="underline"
                style={{ marginTop: "25px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row" id="team-sec">
          <motion.div
            className="col-md-3"
            whileHover={{ scale: 1.05 }} // Animation on hover
            transition={{ type: "spring", stiffness: 300 }}
          >
            <ActionAreaCard
              img={CheifEditor}
              heading="Somendra Gopaliya"
              subheading="Chief Editor"
              para="  As the Chief Editor, Somendra has been responsible for overseeing the daily operations, editorial policies, and the development of the newspaper's content."
            />
          </motion.div>
          <div className="col-md-3">{/* Additional cards can go here */}</div>
        </div>
      </div>
      <Footer id="footer" />
    </motion.div>
  );
};

export default About;
